<template>
  <div>
    <v-container class="v-container-fluid" fluid v-if="showShoppingCart">
      <v-card class="mx-3 mt-2 pb-7 mb-4" v-for="(item, index) in items.results" :key="index">
        <v-row class="pa-4" style="margin: auto;">
          <div style="font-size: 24px; font-weight: 600;">{{ item.sName }}</div>
        </v-row>
        <div style="display: flex; gap: 50px; overflow: auto; white-space: nowrap; justify-content: center;" class="pa-4">
          <v-card>
            <v-toolbar dense dark color="#283c4d" elevation="2">
              <v-toolbar-title>Costo del producto</v-toolbar-title>
            </v-toolbar>
            <v-data-table :headers="texts.headersProducto" :disable-sort="true" hide-default-footer :items="item.aProducts">
              <template v-slot:header.dBoxesPerPallet="props">
                <span style="white-space: nowrap;">{{ props.header.text }}</span>
              </template>
              <template v-slot:item.sProductName="props">
                <span style="text-wrap: nowrap;">{{ props.item.sProductName }}</span>
              </template>
              <template v-slot:item.cajas="props">
                <span style="text-wrap: nowrap;">{{ props.item.cajas }}</span>
              </template>
              <template v-slot:item.cajasPorPallet="props">
                <span style="text-wrap: nowrap;">{{ props.item.cajasPorPallet }}</span>
              </template>
              <template v-slot:item.dUnitPriceUSD="{ item }">
                <span style="text-wrap: nowrap;"> {{ "$ " + formatMoney(item.dUnitPriceUSD) + " USD" }} </span>
              </template>
              <template v-slot:item.dSubTotalPrice="{ item }">
                <span style="text-wrap: nowrap;"> {{ "$ " + formatMoney(item.dSubTotalPrice) + " USD" }} </span>
              </template>
              <template v-slot:item.dFreightPerBox="{ item }">
                <span style="text-wrap: nowrap;"> {{ "$ " + formatMoney(item.dFreightPerBox) + " USD" }} </span>
              </template>
              <template v-slot:item.sCustomPerBox="{ item }">
                <span style="text-wrap: nowrap;"> {{ "$ " + formatMoney(item.sCustomPerBox) + " USD" }} </span>
              </template>
              <template v-slot:item.dTotalInOutPerBox="{ item }">
                <span style="text-wrap: nowrap;"> {{ "$ " + formatMoney(item.dTotalInOutPerBox) + " USD" }} </span>
              </template>
              <template v-slot:item.sLogisticCost="{ item }">
                <span style="text-wrap: nowrap;"> {{ "$ " + formatMoney(item.sLogisticCost) + " USD" }} </span>
              </template>
              <template v-slot:item.sTotalPerBox="props">
                <span style="text-wrap: nowrap;">{{ "$ " + formatMoney(props.item.sTotalPerBox) + " USD" }}</span>
              </template>
              <template v-slot:item.sTotal="{ item }">
                <span style="text-wrap: nowrap;"> {{ "$ " + formatMoney(item.sTotal) + " USD" }} </span>
              </template>
            </v-data-table>
          </v-card>
        </div>
      </v-card>
      <div class="mx-3" style="display: flex; justify-content: flex-end; flex-direction: column; align-items: flex-end;">
        <div class="d-flex mt-5" style="width: 100%">
          <p class="f-s16 mon-bold mx-2">
            {{ texts.breakDownCosts }}
          </p>
        </div>
        <v-data-table
          :no-data-text="'Sin datos'"
          :hide-default-footer="true"
          :disable-sort="true"
          :headers="texts.headersComisiones"
          :items="SalesOrderFees"
          class="tableOrders elevation-1"
          style="width: 100%"
        >
          <template v-slot:item.dTotal="{ item }">
            <span> $ {{ formatMoney(item.dTotal) }} USD </span>
          </template>
        </v-data-table>
        <v-card
          style="width: 100%; margin-top: 10px !important"
          class="content-shadow mt-5 background3194FF d-flex justify-center align-center text-center"
        >
          <span class="text-center f-s20 mon-bold color000000 mx-3">
            Total :
          </span>
          <span class="text-center f-s16 mt-1 mon-bold color000000"> $ {{ formatMoney(items.iTotal) }} USD </span>
        </v-card>
      </div>
      <div class="mx-3 mt-3" style="display: flex; justify-content: flex-end;">
        <v-btn @click="dialogConfirmOrder = true" class="gradient-button">{{ "Confirm" }}</v-btn>
      </div>
    </v-container>
    <div style="height: 64vh" v-else>
      <div class="empty-content display-flex align-items-center justify-content-center" style="height: 100%;">
        <div>
          <div class="display-flex justify-content-center">
            <img width="170px" src="@/assets/icons/empty.gif" alt="proplat-image" />
          </div>
          <p class="text-empty mon-regular mb-9" v-html="'Sorry, there are no items in the shopping cart yet.'" />
        </div>
      </div>
    </div>
    <v-dialog persistent v-model="dialogConfirmOrder" :width="'40%'">
      <div class="container-dialog">
        <close-dialog-component @closeDialog="closeDialog" />
        <p class="text-title-dialog mon-bold">
          {{ "Authenticate your purchase order" }}
        </p>
        <div class="display-flex align-items-center justify-content-center mb-3">
          <img width="90px" src="@/assets/icons/padlock.png" alt="" />
        </div>
        <div class="display-flex align-items-center justify-content-center">
          <p class="text-description mon-regular">
            {{ "By authenticating your purchase, you can get your pick up number." }}
          </p>
        </div>
        <div class="display-flex align-items-center mt-9" style="justify-content: center;">
          <v-btn
            class="button-delete mon-bold ml-5"
            elevation="0"
            @click="confirmOrder()"
            :loading="bLoading"
          >
            {{ "Confirm" }}
          </v-btn>
        </div>
      </div>
    </v-dialog>
    <v-overlay style="z-index: 99999999;" :value="bLoading">
      <div style="display: flex; flex-direction: column; width: 200px;align-items: center;">
        <v-progress-circular :size="50" color="black" indeterminate></v-progress-circular>
        <span class="mt-3">Loading...</span>
      </div>
    </v-overlay>
  </div>
</template>

<script>
import { parse } from "path";

export default {
  props: {
    texts: {
      type: Object,
      default: "",
    },
  },
  name: "AboutContentLayout",
  data() {
    return {
      aArticle: [],
      loadings: {
        loadingWarehouseAndPayment: false,
        loadingCustomers: false,
        loadingShoppingCart: false,
      },
      items: [],
      imageSelected: "",
      textTypeProduct: "",
      textPiecesPerBox: "",
      textWeightPerBox: "",
      textBoxesPerPallet: "",
      amount: 0,
      lengthImages: 0,
      nameBox: "",
      imageProvider: "",
      lengthLogo: 0,
      dialogConfirmOrder: false,
      existProvider: "",
      isResponsive: false,
      password: "",
      bShowPassword: false,
      acepptTerms: false,
      acepptPallets: false,
      bLoading: false,
      showShoppingCart: false,
      aduana: "260",
      warehouse: [{ name: "McAllen" }],
      paymentMethods: [{ name: "Credito Proplat" }, { name: "Transferencia Bancaria" }, { name: "Cheque" }],
      headersProducto: [
        { text: "Producto", value: "sProductName", align: "center" },
        { text: "Cajas", value: "dQuantity", align: "center" },
        { text: "Cajas por pallet", value: "dBoxesPerPallet", align: "center" },
        { text: "Precio USD", value: "dUnitPriceUSD", align: "center" },
        { text: "Costo de in&Out", value: "dTotalInOutPerBox", align: "center" },
        { text: "Costo Logistico", value: "sLogisticCost", align: "center" },
        { text: "Precio por caja", value: "sTotalPerBox", align: "center" },
        { text: "Total con costo operacional", value: "sTotal", align: "center" },
      ],
      SalesOrderFees: [],
      headersComisiones: [
        {
          text: "Nombre",
          value: "sFeeName",
          cellClass: "mon-regular f-s12 color000000",
          class: "f-s14",
        },
        {
          text: "Subtotal de comisiones",
          value: "dTotal",
          cellClass: "mon-bold f-s12 color000000  background3194FF",
          class: "mon-bold f-s14 background3194FF",
          align: "center",
        },
      ],
      selectedCustomer: { name: "McAllen" },
      selectedWarehouse: null,
      selectedPayment: null,
    };
  },
  beforeMount() {},
  created() {
    this.getTemporalShoppingCart();
  },
  methods: {
    redirect: function(url) {
      window.open(url, "blank");
    },
    text10: function() {
      let text = "";
      if (Object.values(this.aArticle).length > 0)
        if (this.selectLanguage == "sp") {
          text = "Piezas por " + this.nameBox;
        } else {
          text = "Aprox pieces per " + this.nameBox;
        }
      return this.capitalize(text);
    },
    text11: function() {
      let text = "";
      if (Object.values(this.aArticle).length > 0)
        if (this.selectLanguage == "sp") {
          text = "Peso por " + this.nameBox;
        } else {
          text = "Weight per " + this.nameBox;
        }
      return this.capitalize(text);
    },
    text12: function() {
      let text = "";
      if (Object.values(this.aArticle).length > 0)
        if (this.selectLanguage == "sp") {
          text = "Número de " + this.nameBox + " Por pallet";
        } else {
          text = "Number of " + this.nameBox + " Per pallet";
        }
      return this.capitalize(text);
    },
    capitalize: function(str) {
      let lower = str.toLowerCase();
      return str.charAt(0).toUpperCase() + lower.slice("1");
    },
    formatDate: function(date) {
      var new_date = MOMENT(date, "DD/MM/YYYY");
      var format_date = MOMENT(new_date).format("MMM. DD, YYYY.");
      return format_date;
    },
    formatMoney: function(money) {
      return FORMAT_MONEY(money);
    },
    closeDialog: function() {
      this.dialogConfirmOrder = false;
      this.password = "";
      this.acepptTerms = false;
      this.acepptPallets = false;
    },
    redirecVendorDetail: function(id) {
      this.$store.commit("setCommingFromCart", true);
      this.$router.push(`/vendor/detail/${id}`);
    },
    getTemporalShoppingCart() {
      this.loadings.loadingShoppingCart = true;
      this.showShoppingCart = false;
      this.items = [];

      DB.get(`${URI}/api/v1/${this.selectLanguage}/super-buyer-cart`, {
        headers: {
          Authorization: this.$store.state.sToken ? `Bearer ${this.$store.state.sToken}` : "",
        },
      })
        .then((response) => {
          this.items = response.data;
          if (this.selectLanguage == 'sp') {
            this.SalesOrderFees = [
              { sFeeName: "Subtotal de artículos", dTotal: this.items.subtotal },
              { sFeeName: "Flete México", dTotal: this.items.sTotalFreight },
              { sFeeName: "Proceso Aduanal", dTotal: this.items.sTotalCustoms },
              { sFeeName: "In&Out", dTotal: this.items.sTotalInOut },
            ];
          } else {
            this.SalesOrderFees = [
              { sFeeName: "Item subtotal", dTotal: this.items.subtotal },
              { sFeeName: "Mexico Freight", dTotal: this.items.sTotalFreight },
              { sFeeName: "Customs process", dTotal: this.items.sTotalCustoms },
              { sFeeName: "In&Out", dTotal: this.items.sTotalInOut },
            ];
          }
          this.showShoppingCart = true;
          this.loadings.loadingShoppingCart = false;
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
          this.loadings.loadingShoppingCart = false;
          this.showShoppingCart = false;
        });
    },
    confirmOrder() {
      this.bLoading = true;
      const payload = {
        sBorderWarehouseId: this.items.sBorderWarehouseId,
        sPaymentMethodId: this.items.sPaymentMethodId,
      };
      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      };

      DB.post(`${URI}/api/v1/${this.selectLanguage}/sales-orders/super-buyer`, payload, config)
        .then((response) => {
          this.bLoading = false;
          this.mixSuccess(response.data.message);
          this.$router.push("/order/tab/1");
        })
        .catch((error) => {
          this.bLoading = false;
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    addShoppingCart: function(sProductId) {
      if (this.$store.state.isLogged) {
        const payload = {
          sProductId: sProductId,
          dQuantity: this.amount,
        };
        const config = {
          headers: {
            Authorization: `Bearer ${this.$store.state.sToken}`,
          },
        };

        DB.post(`${URI}/api/v1/${this.selectLanguage}/customers/${this.$store.state.sUserId}/shopping-cart`, payload, config)
          .then((response) => {
            this.mixSuccess(response.data.message);
            this.$store.commit("setFadeNotificationCart", true);
          })
          .catch((error) => {
            this.mixError(error.response.data.message, error.response.status);
          });
      } else {
        this.mixError(this.texts.about.textNoSession);
      }
    },
    getArticles: function() {
      DB.get(`${URI}/api/v1/${this.selectLanguage}/products/${this.$route.params.id}`, {
        headers: {
          Authorization: this.$store.state.sToken ? `Bearer ${this.$store.state.sToken}` : "",
        },
        params: {
          bCustomer: true,
          aImagesSizes: "sm,md,lg",
          sBorderWarehouseId: "2a9a5c8f-5806-4d00-bc92-45a14c7974ad",
        },
      })
        .then((response) => {
          let arr = response.data.results;
          this.aArticle = arr;
          this.textTypeProduct = arr.oMeasurementUnit.sName;
          this.textPiecesPerBox = arr.oMeasurementUnit.oData.dPiecesPerBox;
          this.textWeightPerBox = arr.oMeasurementUnit.oData.dWeightPerBox;
          this.textBoxesPerPallet = arr.oMeasurementUnit.oData.dBoxesPerPallet;
          this.imageSelected = arr.aImages[0].oImages.lg;
          this.lengthImages = arr.aImages.length;
          this.amount = arr.dMinAmountAllowed;
          this.nameBox = arr.oMeasurementUnit.sName;
          this.lengthLogo = Object.keys(arr.sLogoVendorEnterpriseKey).length;
          this.imageProvider = arr.sLogoVendorEnterpriseKey.sm;
          this.existProvider = arr.sVendorEnterpriseId;
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    selectImage: function(e) {
      this.imageSelected = e.oImages.lg;
    },
    minAmount: function() {
      if (this.amount > this.aArticle.dMinAmountAllowed) {
        this.amount--;
      }
    },
    maxAmount: function() {
      if (this.amount < this.aArticle.dInventoryQuantity) {
        this.amount++;
      }
    },
    soloNumbers: function(evt) {
      if ((evt.which != 8 && evt.which != 0 && evt.which < 48) || evt.which > 57) {
        evt.preventDefault();
      }
    },
    ajustAmount: function(evt) {
      if (evt.target.value === "" || evt.target.value === null) {
        this.amount = this.aArticle.dMinAmountAllowed;
      } else if (parseInt(evt.target.value) === 0 || parseInt(evt.target.value) < this.aArticle.dMinAmountAllowed) {
        this.amount = this.aArticle.dMinAmountAllowed;
      }
    },
    noEmpty: function(evt) {
      if (evt.target.value == "") {
        this.amount = this.aArticle.dMinAmountAllowed;
      }
    },
    // ajustAmount: function (evt) {
    //   if (Number(evt.target.value) >= 100) {
    //     this.amount = 100;
    //   } else if (evt.target.value == "00") {
    //     this.amount = aArticle.dMinAmountAllowed;
    //   }
    // },
    // noEmpty: function (evt) {
    //   if (evt.target.value == "") {
    //     this.amount = aArticle.dMinAmountAllowed;
    //   }
    // },
  },
  computed: {
    selectLanguage: function() {
      return this.$store.state.language;
    },
  },
  watch: {
    selectLanguage: function() {
      if (this.selectLanguage) {
        this.getTemporalShoppingCart();
      }
    },
    //VARIBALKES
    $route(to, from) {
    },
  },
};
</script>

<style scoped>
.background3194FF {
  background-color: #dfeeff !important;
}
.content-shadow {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #e5e5e5;
  border-radius: 9px;
  opacity: 1;
  padding: 20px;
}
.gradient-button {
  background: transparent linear-gradient(228deg, #ffc556 0%, #fffc81 100%) 0% 0% no-repeat padding-box;
}
.size-checkbox {
  width: 85%;
}

.size-input-password {
  width: 50%;
}

.text-link {
  color: #0971fb;
}

.text-link:hover {
  text-decoration: underline;
  cursor: pointer;
}

.button-cancel {
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #283c4d;
  border-radius: 10px;
  opacity: 1;
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: #283c4d;
  opacity: 1;
  height: 40px !important;
  text-transform: initial;
  padding-left: 35px !important;
  padding-right: 35px !important;
  width: 45%;
  background-color: #ffffff !important;
}

.button-delete {
  height: 40px !important;
  text-transform: initial;
  background: transparent linear-gradient(264deg, #ffc431 0%, #fffc81 100%) 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: #283c4d;
  padding-left: 35px !important;
  padding-right: 35px !important;
  width: 45%;
}

.text-terms {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #707070;
  margin-bottom: 0px;
}

.container-dialog {
  width: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #e5e5e5;
  border-radius: 6px;
  opacity: 1;
  padding: 25px;
  position: relative;
}

.text-title-dialog {
  text-align: center;
  letter-spacing: 0px;
  color: #283c4d;
  opacity: 1;
  font-size: 25px;
}

.text-description {
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: #283c4d;
  opacity: 1;
}

.button-next {
  height: 40px !important;
  background: transparent linear-gradient(264deg, #ffc431 0%, #fffc81 100%) 0% 0% no-repeat padding-box;
  border-radius: 10px;
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: #283c4d !important;
  opacity: 1;
  text-transform: initial;
}

.text-return-cart {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #0089ff;
  opacity: 1;
  margin-bottom: 0px;
}

.text-return-cart:hover {
  text-decoration: underline;
  cursor: pointer;
}

.text-title {
  text-align: left;
  font-size: 16px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-bottom: 0px;
}

.text-location {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.3;
  margin-bottom: 0px;
}

.content-card {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #e5e5e5;
  border-radius: 9px;
  opacity: 1;
  padding: 10px;
  margin-bottom: 15px;
  position: relative;
}

.content-logo {
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #8bbbdf 0% 0% no-repeat padding-box;
  border-radius: 19px;
  text-align: center;
  font-size: 35px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

@media (max-width: 600px) {
  .container-dialog {
    padding: 10px;
  }

  .size-input-password {
    width: 90%;
  }

  .text-title-dialog {
    font-size: 20px;
  }

  .text-description {
    font-size: 11px;
  }

  .text-terms {
    font-size: 12px;
  }
}

.gradient-button {
  background: transparent linear-gradient(228deg, #ffc556 0%, #fffc81 100%) 0% 0% no-repeat padding-box;
}
</style>
